import React from "react";
import Testimonial from "../testimonial";
import ChooseFashion from "../choosefashion";
import "./style.scss";
import appleBtn from "../../assets/img/app-get-ios.png";
import androidBtn from "../../assets/img/app-get-android.png";

function Stastep({ title, step1, step2, step3, active }) {
    return (
        <div className="stastep" style={{ marginTop: -100 }}>
            {/* <div className="stastep-title">
                <div className="app-container">
                    <div className="stastep-title-tag"></div>
                    <div className="stastep-title-section">{title.left}</div>
                </div>
            </div> */}
            <div className="stastep-step1">
                <div className="app-container">
                    <div className="stastep-step1-section">
                        <div className="stastep-step1-left">
                            <div className="stastep-step-num">{step1.num}</div>
                            <div className="stastep-step-title">
                                {step1.title}
                            </div>
                            <div className="stastep-step-desc">
                                {step1.desc}
                                <div
                                    style={{
                                        display: "flex",
                                        height: 50,
                                        marginTop: 20,
                                    }}
                                >
                                    <a
                                        href="https://apps.apple.com/us/app/fashone/id1500376922"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={appleBtn}
                                            alt="Get for iOS"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.fashone"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={androidBtn}
                                            alt="Get for Android"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="stastep-step1-right">
                            <img src={step1.img} alt="step1back"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stastep-step2">
                <div className="app-container">
                    <div className="stastep-step2-section">
                        <div className="stastep-step2-left">
                            <img src={step2.img} alt="step2back"></img>
                        </div>
                        <div className="stastep-step2-right">
                            <div className="stastep-step-num">{step2.num}</div>
                            <div className="stastep-step-title">
                                {step2.title}
                            </div>
                            <div className="stastep-step-desc">
                                {step2.desc}
                                <div
                                    style={{
                                        display: "flex",
                                        height: 50,
                                        marginTop: 20,
                                    }}
                                >
                                    <a
                                        href="https://apps.apple.com/us/app/fashone/id1500376922"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={appleBtn}
                                            alt="Get for iOS"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.fashone"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={androidBtn}
                                            alt="Get for Android"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stastep-step3">
                <div className="app-container">
                    <div className="stastep-step3-section">
                        <div className="stastep-step3-left">
                            <div className="stastep-step-num">{step3.num}</div>
                            <div className="stastep-step-title">
                                {step3.title}
                            </div>
                            <div className="stastep-step-desc">
                                {step3.desc}
                                <div
                                    style={{
                                        display: "flex",
                                        height: 50,
                                        marginTop: 20,
                                    }}
                                >
                                    <a
                                        href="https://apps.apple.com/us/app/fashone/id1500376922"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={appleBtn}
                                            alt="Get for iOS"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.fashone"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            height: "100%",
                                            maxWidth: 150,
                                        }}
                                    >
                                        <img
                                            src={androidBtn}
                                            alt="Get for Android"
                                            style={{ height: "100%" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="stastep-step3-right">
                            <img src={step3.img} alt="step3back"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stastep-testimonial">
                <div className="app-container">
                    <div className="stastep-testimonial-tag"></div>
                    <div className="stastep-testimonial-title">
                        Client Testimonial
                    </div>
                </div>
                <div className="app-container stastep-testimonial-container">
                    <Testimonial></Testimonial>
                </div>
            </div>
            {active === "sta" ? <ChooseFashion></ChooseFashion> : null}
        </div>
    );
}

export default Stastep;
