import React from "react";
import { Link } from "react-router-dom";
import Header from "../header";
import Register from "../register";
import appleBtn from "../../assets/img/app-get-ios.png";
import androidBtn from "../../assets/img/app-get-android.png";
import girlImg from "../../assets/img/business-girl.jpg";
import landingImg from "../../assets/img/landing-reverse.jpg";
import "./style.scss";

function Landing({ active, register }) {
    console.log(active, register);
    return (
        <div className="landing">
            <Header active={active}></Header>
            <div
                className={`landing-container app-container ${
                    // active === "sta" && register === "register"
                    active === "sta"
                        ? "landing-changeimgsta"
                        : active === "seeker" && register === "register"
                        ? "landing-changeimgseeker"
                        : ""
                }`}
                style={{
                    background:
                        active === "sta"
                            ? `url(${girlImg}) no-repeat center
                        center`
                            : `url(${landingImg}) no-repeat center
                            center`,
                    backgroundSize: "cover",
                    height: "100%",
                }}
            >
                <div
                    className={`landing-left ${
                        register === "register" ? "landing-hide" : ""
                    }`}
                >
                    <div className="landing-left-tag"></div>
                    {active === "sta" ? (
                        <div className="landing-left-desc">
                            Build scalable fashion consulting business with
                            global outreach.
                            <div className="landing-left-desc-subtext">
                                Smart Scheduling. Intelligent Virtual Meetings.
                                Hassle-Free Payments.
                            </div>
                        </div>
                    ) : (
                        <div className="landing-left-desc">
                            Find the virtual fashion experts for all of your
                            problems.
                            <div className="landing-left-desc-subtext">
                                Connect face to face for personalized virtual
                                style advice. Simply at home.
                            </div>
                        </div>
                    )}
                    {/* <div className="landing-left-desc">
                        Find Your Fashion Friend
                    </div> */}
                    <div
                        className={`landing-left-button ${
                            active === "sta" || active === "seeker"
                                ? "landing-hide"
                                : ""
                        }`}
                        style={{
                            display: "flex",
                        }}
                    >
                        <a
                            href="https://apps.apple.com/us/app/fashone/id1500376922"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="landing-left-button-sta">
                                <img src={appleBtn} alt="Get for iOS" />
                            </div>
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.fashone"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="landing-left-button-sta">
                                <img src={androidBtn} alt="Get for Android" />
                            </div>
                        </a>
                    </div>
                    {/* <Link
                        to={`/${
                            active === "sta" ? "Fashionista" : "Fashion"
                        }Register`}
                    >
                        <div
                            className={`landing-sta-button ${
                                active === "sta" || active === "seeker"
                                    ? "landing-show"
                                    : ""
                            }`}
                        >
                            {active === "sta" ? (
                                <span>Fashionista Pre-register</span>
                            ) : (
                                <span>Fashion seeker pre-register</span>
                            )}
                        </div>
                    </Link> */}
                    <div className="landing-left-mobile"></div>
                </div>

                <div
                    className={`landing-right ${
                        register === "register" ? "landing-hide" : ""
                    }`}
                    style={{
                        background:
                            active === "sta"
                                ? `url(${girlImg}) no-repeat center
                            center`
                                : `url(${landingImg}) no-repeat center
                                center`,
                        backgroundSize: "cover",
                        height: "100%",
                    }}
                ></div>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 0,
                        backgroundColor: "rgba(238,238,238, 0.2)",
                    }}
                />
                {register === "register" && (
                    <Register active={active}></Register>
                )}
            </div>
        </div>
    );
}

export default Landing;
