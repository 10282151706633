import React, { useEffect } from "react";
import DocumentMeta from "react-document-meta";

import Landing from "../../components/landing";
import Intro from "../../components/intro";
import Stastep from "../../components/stastep";
import Footer from "../../components/footer";

import STEP1BACK from "../../assets/img/step4-back.png";
import STEP2BACK from "../../assets/img/step5-back.png";
import STEP3BACK from "../../assets/img/step6-back.png";
import "./style.scss";

const seekerdata = {
    title: {
        left: (
            <>
                3 Step use for Fashion Seeker<br></br>
                <span>TO BOOK AND CONSULT</span>
            </>
        ),
    },
    step1: {
        num: "01",
        title: "Search your Fashionista",
        desc:
            "We have different categories of fashion experts according to your fashion requirements. Find them instantly based on your needs, or what suits you best.",
        img: STEP1BACK,
    },
    step2: {
        num: "02",
        title: "Pay and Book Fashionista",
        desc:
            "Pay & book fashionista with easy steps. Don’t forget to write all your queries to help them understand your fashion concerns.",
        img: STEP2BACK,
    },
    step3: {
        num: "03",
        title: "Connect simply with Video Call",
        desc:
            "Connect easily via In-app chat & video call feature with your fashion experts",
        img: STEP3BACK,
    },
    active: "sta",
};
const active = "seeker";

function DefaultFashionseeker() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Landing></Landing>
            <Intro active={active}></Intro>
            <Stastep {...seekerdata}></Stastep>
            <Footer></Footer>
        </>
    );
}

function MainFashionseeker() {
    const meta = {
        title:
            "Fashion Seeker-Get Ready for Next Fashion Trend or Earn Money at Home as Fashionista-Fashone.",
        description: "3 Step use for Fashion Seeker TO BOOK AND CONSULT",
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <DocumentMeta {...meta}>
                <Landing active={active}></Landing>
                <Intro active={"main"}></Intro>
                <Stastep {...seekerdata}></Stastep>
                <Footer></Footer>
            </DocumentMeta>
        </>
    );
}

export { DefaultFashionseeker, MainFashionseeker };
