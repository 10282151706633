import React from "react";
import { Link } from "react-router-dom";
import LOGO from "../../assets/img/logo.png";
import LOGOMOBILE from "../../assets/img/logo-mobile.png";
import "./style.scss";

function Header({ active }) {
    const onBurger = () => {
        document
            .getElementById("burgerMenu")
            .classList.toggle("header-mobile-active");
        document
            .getElementById("mobileMenu")
            .classList.toggle("header-mobile-menu-active");
    };

    return (
        <div className="header">
            <div className="header-container">
                <a href="/#">
                    <img className="header-logo" src={LOGO} alt="log"></img>
                    <img
                        className="header-logo-mobile"
                        src={LOGOMOBILE}
                        alt="log"
                    ></img>
                </a>
                <div className="header-menu">
                    <Link to="/Fashionista">
                        <div
                            className={`${
                                active === "sta" ? "header-menu-active" : ""
                            }`}
                        >
                            BUSINESS
                        </div>
                    </Link>

                    <Link to="/login">
                        <div
                            className={`${
                                active === "login" ? "header-menu-active" : ""
                            }`}
                            style={{ color: "#f7931e" }}
                        >
                            LOGIN
                        </div>
                    </Link>
                </div>
                <div
                    id="burgerMenu"
                    className="header-mobile"
                    onClick={() => {
                        onBurger();
                    }}
                >
                    <div className="header-mobile-mark"></div>
                </div>
            </div>
            <div id="mobileMenu" className="header-mobile-menu">
                <Link to="/Fashionista">
                    <div
                        className={`${
                            active === "sta" ? "header-menu-active" : ""
                        }`}
                        onClick={() => {
                            onBurger();
                        }}
                    >
                        BUSINESS
                    </div>
                </Link>
                <Link to="/login">
                    <div
                        className={`${
                            active === "login" ? "header-menu-active" : ""
                        }`}
                        onClick={() => {
                            onBurger();
                        }}
                    >
                        LOGIN
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Header;
