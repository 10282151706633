import React from "react";
import Header from "../../components/header";

const Login = () => {
    const active = "login";
    const innerWidth = window.innerWidth;
    return (
        <div>
            <Header active={active}></Header>
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    padding: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: innerWidth < 600 ? 20 : 40,
                    fontWeight: 500,
                    boxSizing: "border-box",
                }}
            >
                <div>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        You can connect with your fashionista via web-call here.
                    </div>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        Coming Soon!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
